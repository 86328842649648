import React from 'react';

export default function() {
  return (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 760 630"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.50698 353.555C5.21698 579.987 297.612 591.85 430.616 511.923C483.448 480.167 498.847 453.088 563.148 451.177C609.924 449.896 649.976 488.737 698.963 480.798C806.375 463.452 872.561 330.116 861.969 237.373C850.982 141.138 790.655 61.714 701.007 62.046C635.373 62.277 590.109 115.193 551.45 114.596C471.898 113.336 455.994 66.094 395.922 33.505C175.406 -86.258 -3.60402 147.759 0.50698 353.555Z"
      fill="#F7F7F7"
    />
    <path
      opacity="0.12"
      d="M305.482 221.78C301.544 220.703 301.47 215.644 301.47 215.644L305.246 216.396C305.039 218.192 305.119 220.009 305.482 221.78Z"
      fill="black"
    />
    <path
      d="M512.917 83.888V296.515C512.917 299.668 511.665 302.692 509.435 304.921C507.206 307.15 504.182 308.403 501.029 308.403H179.714C176.576 308.383 173.572 307.122 171.361 304.894C169.15 302.666 167.911 299.654 167.915 296.515V83.888C167.911 80.749 169.15 77.737 171.361 75.509C173.572 73.281 176.576 72.02 179.714 72H501.029C502.59 72 504.136 72.308 505.579 72.905C507.021 73.502 508.331 74.378 509.435 75.482C510.539 76.586 511.415 77.896 512.012 79.339C512.61 80.781 512.917 82.327 512.917 83.888Z"
      fill="#448EFD"
    />
    <path
      opacity="0.7"
      d="M512.917 83.888V296.515C512.917 299.668 511.665 302.692 509.435 304.921C507.206 307.15 504.182 308.403 501.029 308.403H179.714C176.576 308.383 173.572 307.122 171.361 304.894C169.15 302.666 167.911 299.654 167.915 296.515V83.888C167.911 80.749 169.15 77.737 171.361 75.509C173.572 73.281 176.576 72.02 179.714 72H501.029C502.59 72 504.136 72.308 505.579 72.905C507.021 73.502 508.331 74.378 509.435 75.482C510.539 76.586 511.415 77.896 512.012 79.339C512.61 80.781 512.917 82.327 512.917 83.888Z"
      fill="white"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M492.917 89.862C494.685 89.862 496.118 88.429 496.118 86.661C496.118 84.893 494.685 83.46 492.917 83.46C491.15 83.46 489.717 84.893 489.717 86.661C489.717 88.429 491.15 89.862 492.917 89.862Z"
      fill="#448EFD"
    />
    <path
      d="M477.622 86.661C477.625 87.298 477.439 87.921 477.087 88.451C476.736 88.982 476.235 89.396 475.647 89.642C475.06 89.888 474.413 89.954 473.789 89.831C473.164 89.709 472.59 89.404 472.138 88.955C471.687 88.505 471.38 87.933 471.254 87.308C471.129 86.684 471.192 86.037 471.435 85.449C471.678 84.86 472.09 84.357 472.619 84.003C473.148 83.649 473.77 83.46 474.407 83.46C475.256 83.464 476.069 83.802 476.671 84.401C477.273 85 477.614 85.812 477.622 86.661Z"
      fill="#448EFD"
    />
    <path
      d="M459.112 86.661C459.115 87.298 458.929 87.921 458.577 88.451C458.226 88.982 457.724 89.396 457.137 89.642C456.55 89.888 455.903 89.954 455.278 89.831C454.654 89.709 454.079 89.404 453.628 88.955C453.177 88.505 452.869 87.933 452.744 87.308C452.619 86.684 452.682 86.037 452.924 85.449C453.167 84.86 453.58 84.357 454.109 84.003C454.638 83.649 455.26 83.46 455.896 83.46C456.746 83.464 457.559 83.802 458.161 84.401C458.762 85 459.104 85.812 459.112 86.661Z"
      fill="#448EFD"
    />
    <path
      d="M487.637 127.045V339.672C487.617 342.808 486.358 345.809 484.133 348.02C481.909 350.23 478.9 351.471 475.764 351.471H154.434C151.298 351.471 148.289 350.23 146.064 348.02C143.84 345.809 142.58 342.808 142.561 339.672V127.045C142.561 123.894 143.811 120.873 146.037 118.644C148.264 116.415 151.284 115.161 154.434 115.157H475.764C478.914 115.161 481.934 116.415 484.16 118.644C486.386 120.873 487.637 123.894 487.637 127.045Z"
      fill="#448EFD"
    />
    <path
      opacity="0.8"
      d="M487.637 127.045V339.672C487.617 342.808 486.358 345.809 484.133 348.02C481.909 350.23 478.9 351.471 475.764 351.471H154.434C151.298 351.471 148.289 350.23 146.064 348.02C143.84 345.809 142.58 342.808 142.561 339.672V127.045C142.561 123.894 143.811 120.873 146.037 118.644C148.264 116.415 151.284 115.161 154.434 115.157H475.764C478.914 115.161 481.934 116.415 484.16 118.644C486.386 120.873 487.637 123.894 487.637 127.045Z"
      fill="white"
    />
    <path
      d="M487.652 140.113H410.675V334.627H487.652V140.113Z"
      fill="#448EFD"
    />
    <path
      opacity="0.2"
      d="M487.652 140.113H410.675V334.627H487.652V140.113Z"
      fill="black"
    />
    <path
      d="M487.652 198.535H410.675V201.013H487.652V198.535Z"
      fill="#FAFAFA"
    />
    <path d="M487.652 259.582H410.675V262.06H487.652V259.582Z" fill="#FAFAFA" />
    <path d="M487.652 319.73H410.675V322.208H487.652V319.73Z" fill="#FAFAFA" />
    <path
      d="M444.244 215.556V244.612L466.191 230.084L444.244 215.556Z"
      fill="#FAFAFA"
    />
    <path
      d="M444.244 276.352V305.423L466.191 290.895L444.244 276.352Z"
      fill="#FAFAFA"
    />
    <path
      d="M444.244 153.682V182.753L466.191 168.21L444.244 153.682Z"
      fill="#FAFAFA"
    />
    <path
      d="M255.423 259.582C255.423 259.582 235.747 288.919 229.612 294.745C229.612 294.745 196.632 297.695 183.063 288.417L179.626 296.839C179.626 296.839 210.954 314.539 232.178 310.217C253.402 305.895 269.051 264.922 269.051 264.922L255.423 259.582Z"
      fill="#FBA68D"
    />
    <path
      d="M351.043 242.134C351.043 242.134 369.849 247.282 381.574 248.27C381.574 248.27 390.424 227.724 391.899 209.066L398.566 209.435C398.566 209.435 404.701 245.777 388.61 260.497C377.489 270.689 340.379 251.81 340.379 251.81L351.043 242.134Z"
      fill="#FBA68D"
    />
    <path
      d="M298.122 161.986C298.122 161.986 272.635 159.523 269.995 185.496C268.537 201.616 271.143 217.843 277.576 232.694C277.576 232.694 292.99 264.066 311.677 259.701C330.364 255.335 346.205 245.217 342.901 228.284C339.597 211.352 333.447 212.06 333.668 188.461C333.89 164.862 311.751 151.16 298.122 161.986Z"
      fill="#423F3F"
    />
    <path
      d="M325.94 199.184C323.27 212.208 317.326 217.399 305.291 216.396C305.084 218.192 305.163 220.009 305.527 221.78C305.826 223.902 306.809 225.869 308.326 227.383C309.843 228.897 311.811 229.876 313.934 230.172C324.258 231.957 318.609 245.246 318.609 245.246L308.285 250.32L294.701 242.488L284.376 231.47H287.99C287.99 231.47 294.981 220.895 287.99 203.092C286.461 202.197 285.172 200.945 284.235 199.442C283.297 197.939 282.738 196.231 282.606 194.464C282.311 188.564 288.403 188.21 290.703 190.423C290.703 190.423 301.19 185.85 299.627 168.181C299.627 168.181 309.951 175.939 323.226 173.682C323.211 173.785 328.595 186.145 325.94 199.184Z"
      fill="#FBA68D"
    />
    <path
      d="M316.456 188.343L316.589 197.458C317.321 197.741 318.105 197.863 318.889 197.817C319.672 197.771 320.437 197.558 321.131 197.193L316.456 188.343Z"
      fill="#A02724"
    />
    <path
      d="M302.488 178.092C302.401 178.112 302.31 178.112 302.223 178.092C302.166 178.058 302.117 178.012 302.079 177.958C302.04 177.905 302.012 177.844 301.997 177.779C301.982 177.715 301.98 177.648 301.991 177.583C302.002 177.518 302.025 177.455 302.06 177.399C302.746 176.352 303.772 175.574 304.965 175.195C306.158 174.816 307.445 174.86 308.609 175.319C308.672 175.339 308.73 175.372 308.78 175.415C308.83 175.458 308.871 175.511 308.9 175.57C308.929 175.629 308.946 175.694 308.949 175.76C308.952 175.826 308.942 175.892 308.919 175.954C308.875 176.078 308.784 176.181 308.666 176.239C308.547 176.297 308.41 176.306 308.285 176.263C307.337 175.875 306.284 175.827 305.305 176.128C304.325 176.429 303.481 177.061 302.916 177.915C302.864 177.977 302.798 178.025 302.724 178.056C302.649 178.087 302.568 178.099 302.488 178.092Z"
      fill="#423F3F"
    />
    <path
      d="M325.32 180.909C325.19 180.91 325.064 180.863 324.966 180.777C323.491 179.434 320.925 180.423 320.895 180.437C320.77 180.481 320.633 180.475 320.512 180.42C320.391 180.365 320.296 180.266 320.246 180.142C320.223 180.08 320.212 180.014 320.214 179.948C320.216 179.882 320.231 179.817 320.259 179.756C320.286 179.696 320.325 179.642 320.374 179.597C320.422 179.551 320.479 179.516 320.541 179.493C320.659 179.493 323.772 178.269 325.659 180.039C325.753 180.133 325.806 180.26 325.806 180.393C325.806 180.526 325.753 180.653 325.659 180.747C325.57 180.841 325.449 180.899 325.32 180.909Z"
      fill="#423F3F"
    />
    <path
      d="M312.577 201.809C310.859 201.881 309.154 201.499 307.631 200.701C306.108 199.904 304.823 198.719 303.904 197.266C303.878 197.182 303.883 197.09 303.917 197.008C303.951 196.926 304.012 196.859 304.091 196.817C304.169 196.775 304.259 196.762 304.346 196.779C304.433 196.797 304.512 196.844 304.568 196.912C305.426 198.245 306.618 199.329 308.026 200.056C309.434 200.784 311.008 201.129 312.591 201.057C312.689 201.057 312.783 201.096 312.852 201.165C312.921 201.234 312.96 201.328 312.96 201.426C312.96 201.528 312.92 201.625 312.848 201.697C312.776 201.769 312.678 201.809 312.577 201.809Z"
      fill="#423F3F"
    />
    <path
      d="M309.937 186.78C309.937 187.871 309.391 188.756 308.653 188.786C307.916 188.815 307.296 187.945 307.267 186.853C307.237 185.762 307.798 184.877 308.535 184.847C309.273 184.818 309.907 185.688 309.937 186.78Z"
      fill="#423F3F"
    />
    <path
      d="M323.049 186.898C323.049 187.974 322.503 188.874 321.766 188.904C321.028 188.933 320.409 188.063 320.364 186.971C320.32 185.88 320.91 184.995 321.648 184.966C322.385 184.936 323.019 185.806 323.049 186.898Z"
      fill="#423F3F"
    />
    <path
      d="M308.329 184.892L311.677 183.933C311.677 183.933 311.072 185.732 308.329 184.892Z"
      fill="#423F3F"
    />
    <path
      d="M321.648 184.965L324.111 184.611C324.009 184.838 323.851 185.034 323.653 185.183C323.455 185.332 323.222 185.429 322.976 185.464C322.731 185.5 322.48 185.472 322.248 185.385C322.016 185.298 321.809 185.154 321.648 184.965Z"
      fill="#423F3F"
    />
    <path
      d="M357.415 241.544L345.866 266.972L339.73 264.745C339.73 264.745 340.35 310.91 335.306 334.627H275.276L271.824 271.618L268.683 278.196L246.559 265.777C246.559 265.777 252.68 240.703 262.237 236.279C266.795 234.169 276.515 232.547 286.515 231.5C295.626 230.468 304.795 230.034 313.963 230.202C313.963 230.202 314.907 230.202 316.574 230.202C330.887 230.737 344.875 234.622 357.415 241.544Z"
      fill="#448EFD"
    />
    <path
      d="M391.899 209.066C389.622 205.829 388.109 202.117 387.474 198.21C386.851 194.005 387.241 189.713 388.61 185.688L400.527 185.275L402.002 195.703C402.586 200.543 401.373 205.429 398.595 209.435L391.899 209.066Z"
      fill="#FBA68D"
    />
    <path
      d="M183.063 288.418L176.853 277.341L169.301 274.715L163.933 284.745L168.726 291.323C168.726 291.323 175.054 296.692 179.626 296.839L183.063 288.418Z"
      fill="#FBA68D"
    />
    <path
      d="M316.5 230.202C316.397 246.102 306.456 263.521 306.456 263.521C297.606 254.981 288.757 236.662 286.441 231.47C295.552 230.438 304.721 230.004 313.889 230.172C313.889 230.172 314.892 230.143 316.5 230.202Z"
      fill="#FBA68D"
    />
    <path
      opacity="0.12"
      d="M268.668 278.196L271.765 261.131L271.824 271.633L268.668 278.196Z"
      fill="black"
    />
    <path
      opacity="0.12"
      d="M345.896 266.972L335.984 259.582L339.76 264.745L345.896 266.972Z"
      fill="black"
    />
    <path
      d="M280.6 541.546L294.789 556.9L261.868 588.213L250.128 579.865L280.6 541.546Z"
      fill="#FBA68D"
    />
    <path
      d="M264.745 587.476C265.541 592.254 266.706 597.579 273.491 606.93C274.966 609.01 271.043 612.903 266.603 608.788C262.164 604.673 255.792 598.213 252.576 595.248C247.34 590.396 245.202 588.788 240.939 585.012C240.873 584.966 240.814 584.912 240.762 584.85C240.559 584.638 240.4 584.389 240.294 584.115C240.187 583.842 240.136 583.55 240.143 583.257C240.15 582.963 240.215 582.674 240.334 582.406C240.453 582.138 240.623 581.896 240.836 581.694L250.172 574.127C250.388 573.96 250.657 573.877 250.93 573.896C251.202 573.915 251.457 574.034 251.647 574.231L263.55 585.234C264.176 585.835 264.595 586.62 264.745 587.476Z"
      fill="#423F3F"
    />
    <path
      d="M262.96 587.195C262.923 587.138 262.903 587.072 262.903 587.004C262.903 586.936 262.923 586.869 262.96 586.812C263.005 586.781 263.056 586.761 263.11 586.754C263.163 586.746 263.218 586.751 263.27 586.768C263.948 586.989 270.01 588.862 270.644 590.721C270.707 590.892 270.725 591.077 270.697 591.258C270.668 591.438 270.595 591.609 270.482 591.753C270.32 591.991 270.098 592.182 269.84 592.309C269.581 592.435 269.294 592.493 269.007 592.476C267.818 592.228 266.738 591.608 265.925 590.706C264.834 589.626 263.842 588.452 262.96 587.195ZM264.096 587.638C266.116 590.204 267.945 591.738 269.066 591.738C269.257 591.751 269.448 591.712 269.619 591.627C269.79 591.541 269.935 591.412 270.04 591.252C270.075 591.191 270.093 591.122 270.093 591.052C270.093 590.983 270.075 590.914 270.04 590.853C269.946 590.65 269.81 590.469 269.641 590.322C267.946 589.14 266.075 588.234 264.096 587.638Z"
      fill="#448EFD"
    />
    <path
      d="M262.871 587.063C262.856 587.008 262.857 586.95 262.872 586.895C262.888 586.841 262.918 586.791 262.96 586.753C263.063 586.65 265.733 584.201 268.004 584.393C268.683 584.447 269.313 584.764 269.759 585.278C270.394 586.001 270.29 586.517 270.099 586.753C269.258 588.066 264.848 587.903 263.093 587.269L262.975 587.195C262.933 587.157 262.898 587.113 262.871 587.063ZM267.989 585.057C266.451 585.203 265.002 585.845 263.86 586.886C265.807 587.343 269.022 587.21 269.523 586.428C269.523 586.428 269.686 586.178 269.273 585.706L269.125 585.558C268.813 585.269 268.413 585.092 267.989 585.057Z"
      fill="#448EFD"
    />
    <path
      d="M274.155 569.585L293.491 569.304L290.807 617.904L276.898 617.417L274.155 569.585Z"
      fill="#FBA68D"
    />
    <path
      d="M294.081 614.319C298.019 617.417 299.981 620.676 311.013 623.081C313.476 623.626 313.55 629.393 307.724 629.349C301.898 629.305 295.689 628.789 291.5 628.789C284.641 628.789 282.031 629.098 276.603 629.098C276.281 629.093 275.964 629.024 275.669 628.895C275.375 628.767 275.108 628.582 274.885 628.35C274.661 628.119 274.486 627.845 274.368 627.546C274.251 627.247 274.193 626.927 274.199 626.606L275.143 614.305C275.158 614.041 275.277 613.794 275.473 613.618C275.669 613.441 275.926 613.349 276.19 613.361L291.81 613.464C292.638 613.502 293.433 613.801 294.081 614.319Z"
      fill="#423F3F"
    />
    <path
      d="M285.025 317.193C283.344 347.356 322.99 437.519 327.415 469.112C313.757 500.838 278.019 541.281 278.019 541.281L294.951 559.747C294.951 559.747 354.244 506.9 357.326 470.071C359.893 438.581 337.621 320.217 337.621 320.217L285.025 317.193Z"
      fill="#448EFD"
    />
    <path
      d="M285.025 317.193C283.344 347.356 322.99 437.519 327.415 469.112C313.757 500.838 278.019 541.281 278.019 541.281L294.951 559.747C294.951 559.747 354.244 506.9 357.326 470.071C359.893 438.581 337.621 320.217 337.621 320.217L285.025 317.193Z"
      fill="#423F3F"
    />
    <path
      d="M278.461 536.974L300.615 558.006L294.641 561.753L274.376 543.523L278.461 536.974Z"
      fill="#448EFD"
    />
    <path
      opacity="0.3"
      d="M297.783 374.451C307.075 403.153 318.993 434.407 324.568 455.749C325.586 432.534 324.804 391.176 308.565 368.728C300.984 358.153 297.99 363.418 297.783 374.451Z"
      fill="black"
    />
    <path
      d="M274.922 317.547C263.402 349.273 272.37 420.896 272.989 472.224C269.538 510.912 274.155 569.57 274.155 569.57L294.951 570.425C294.951 570.425 301.22 501.192 305.497 457.991C309.125 421.619 322.075 319.347 322.075 319.347L274.922 317.547Z"
      fill="#448EFD"
    />
    <path
      d="M274.273 316.441C262.753 348.152 272.37 420.896 272.989 472.224C269.538 510.912 274.155 569.57 274.155 569.57L294.951 570.425C294.951 570.425 301.22 501.192 305.497 457.991C309.125 421.619 322.075 319.347 322.075 319.347L274.273 316.441Z"
      fill="#423F3F"
    />
    <path
      d="M271.131 564.467L298.093 565.219L296.426 572.712L272.591 573.39L271.131 564.467Z"
      fill="#448EFD"
    />
    <path
      d="M299.288 615.293C298.049 616.488 294.863 615.912 292.975 615.485C292.91 615.464 292.852 615.428 292.806 615.379C292.759 615.33 292.726 615.27 292.709 615.204C292.698 615.143 292.705 615.08 292.728 615.023C292.752 614.965 292.791 614.916 292.842 614.88C293.285 614.57 297.267 611.93 298.993 612.623C299.183 612.705 299.349 612.835 299.473 613C299.597 613.166 299.676 613.362 299.701 613.567C299.765 613.819 299.772 614.083 299.721 614.338C299.67 614.593 299.562 614.834 299.406 615.042C299.395 615.137 299.354 615.225 299.288 615.293ZM293.889 614.939C296.338 615.44 298.152 615.367 298.816 614.762C298.956 614.607 299.052 614.417 299.093 614.212C299.135 614.007 299.12 613.794 299.052 613.597C299.052 613.504 299.023 613.413 298.971 613.337C298.918 613.26 298.843 613.202 298.757 613.169C297.886 612.785 295.526 613.951 293.889 614.983V614.939Z"
      fill="#448EFD"
    />
    <path
      d="M293.211 615.367C293.159 615.389 293.104 615.4 293.049 615.4C292.993 615.4 292.938 615.389 292.886 615.367C292.837 615.336 292.794 615.295 292.761 615.246C292.728 615.198 292.706 615.144 292.695 615.087C292.695 614.91 292.459 610.912 293.86 609.585C294.038 609.409 294.255 609.279 294.494 609.204C294.732 609.13 294.985 609.114 295.231 609.157C295.5 609.167 295.757 609.263 295.967 609.43C296.177 609.597 296.327 609.827 296.397 610.086C296.4 610.782 296.264 611.472 295.998 612.115C295.733 612.758 295.341 613.342 294.848 613.833C294.388 614.428 293.835 614.946 293.211 615.367ZM293.329 614.452C294.597 613.405 296.013 611.163 295.762 610.204C295.734 610.078 295.659 609.967 295.553 609.893C295.446 609.819 295.316 609.788 295.187 609.806C295.033 609.777 294.873 609.787 294.723 609.836C294.574 609.885 294.439 609.971 294.332 610.086L294.199 610.234C293.523 611.532 293.222 612.993 293.329 614.452Z"
      fill="#448EFD"
    />
    <path
      d="M487.637 127.045V140.113H142.502V127.045C142.502 123.894 143.752 120.873 145.979 118.644C148.205 116.415 151.225 115.161 154.375 115.157H475.764C478.914 115.161 481.934 116.415 484.16 118.644C486.387 120.873 487.637 123.894 487.637 127.045Z"
      fill="#423F3F"
    />
    <path
      d="M487.637 334.627V339.672C487.617 342.808 486.358 345.809 484.133 348.02C481.909 350.23 478.9 351.471 475.764 351.471H154.434C151.298 351.471 148.289 350.23 146.064 348.02C143.84 345.809 142.58 342.808 142.561 339.672V334.627H487.637Z"
      fill="#423F3F"
    />
    <path
      d="M210.703 134.11H160.29C158.841 134.11 157.451 133.535 156.425 132.512C155.4 131.489 154.821 130.101 154.818 128.652C154.818 127.201 155.394 125.809 156.42 124.783C157.446 123.757 158.838 123.18 160.29 123.18H210.703C211.421 123.18 212.133 123.322 212.797 123.597C213.461 123.872 214.064 124.275 214.572 124.783C215.08 125.291 215.483 125.894 215.758 126.558C216.033 127.222 216.175 127.934 216.175 128.652C216.171 130.101 215.593 131.489 214.567 132.512C213.541 133.535 212.152 134.11 210.703 134.11Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M238.078 134.11H230.703C229.256 134.11 227.868 133.535 226.844 132.511C225.821 131.488 225.246 130.1 225.246 128.652C225.246 127.204 225.82 125.814 226.843 124.788C227.866 123.763 229.254 123.184 230.703 123.18H238.078C239.529 123.18 240.921 123.757 241.947 124.783C242.973 125.809 243.55 127.201 243.55 128.652C243.546 130.101 242.968 131.489 241.942 132.512C240.916 133.535 239.526 134.11 238.078 134.11Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M228.269 162.546H139.168C132.448 162.546 127 167.994 127 174.715V206.455C127 213.176 132.448 218.624 139.168 218.624H228.269C234.99 218.624 240.438 213.176 240.438 206.455V174.715C240.438 167.994 234.99 162.546 228.269 162.546Z"
      fill="#448EFD"
    />
    <path
      d="M244.774 172.119V208.992C244.784 210.251 244.545 211.499 244.07 212.666C243.596 213.832 242.897 214.893 242.011 215.788C241.126 216.683 240.072 217.394 238.911 217.881C237.75 218.367 236.505 218.62 235.246 218.624H201.072C190.747 222.193 197.207 236.485 197.428 237.001C195.463 236.328 193.623 235.331 191.986 234.051C184.611 228.152 185.644 218.638 185.644 218.638H140.85C138.311 218.638 135.876 217.63 134.081 215.835C132.286 214.039 131.278 211.605 131.278 209.066V172.193C131.278 169.654 132.286 167.219 134.081 165.424C135.876 163.629 138.311 162.62 140.85 162.62H235.246C237.765 162.628 240.18 163.63 241.964 165.409C243.749 167.188 244.758 169.599 244.774 172.119Z"
      fill="#448EFD"
    />
    <path
      opacity="0.2"
      d="M240.423 172.119V208.992C240.433 210.257 240.191 211.512 239.712 212.683C239.234 213.854 238.527 214.918 237.634 215.814C236.741 216.71 235.679 217.42 234.509 217.902C233.34 218.384 232.086 218.629 230.821 218.624H196.721C189.169 221.219 190.585 229.538 191.986 234.037C184.611 228.137 185.644 218.624 185.644 218.624H136.558C135.301 218.624 134.057 218.376 132.897 217.895C131.736 217.413 130.682 216.708 129.794 215.819C128.907 214.93 128.203 213.875 127.724 212.713C127.244 211.552 126.998 210.308 127 209.051V172.178C126.998 170.921 127.244 169.677 127.724 168.516C128.203 167.354 128.907 166.299 129.794 165.41C130.682 164.521 131.736 163.816 132.897 163.334C134.057 162.853 135.301 162.605 136.558 162.605H230.821C232.076 162.6 233.32 162.841 234.482 163.316C235.644 163.791 236.701 164.49 237.592 165.373C238.484 166.257 239.193 167.307 239.678 168.465C240.164 169.622 240.417 170.864 240.423 172.119Z"
      fill="white"
    />
    <path
      d="M231.588 171.455H135.245V176.558H231.588V171.455Z"
      fill="#448EFD"
    />
    <path
      d="M231.588 180.821H135.245V185.924H231.588V180.821Z"
      fill="#448EFD"
    />
    <path
      d="M231.588 190.202H135.245V195.305H231.588V190.202Z"
      fill="#448EFD"
    />
    <path
      opacity="0.1"
      d="M231.588 171.455H135.245V176.558H231.588V171.455Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M231.588 180.821H135.245V185.924H231.588V180.821Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M231.588 190.202H135.245V195.305H231.588V190.202Z"
      fill="black"
    />
  </svg>
);
}